import React from 'react'
import ReactMarkdown, { ReactMarkdownOptions } from 'react-markdown'
import RemarkBreaks from 'remark-breaks'
import RemarkGFM from 'remark-gfm'
import { Text } from '@hub/text'
import { Box } from '@hub/box'
import { Link } from '@hub/link'
import { HubStyleObject } from '@hub/design-system-base'
import { H1, H2, H3, H4, H5, H6 } from '@hub/heading'
import { ListItem, OrderedList, UnorderedList } from '@hub/list'
import { CloudinaryImage } from '../cloudinary-image'
import { getYoutubeUrlDetails } from '../../helpers/youtube-url'
import YoutubeLink from '../../components/youtube-link'
import { markdownStyle } from './styles'

const DEFAULT_MARKDOWN_IMAGE_WIDTH = '1200'

export interface MarkdownProps {
  content?: string | null
  components?: ReactMarkdownOptions['components']
  wrapperClass?: string
  sx?: HubStyleObject
  dataTestId?: string
}

interface MarkdownLinkProps {
  title?: string
  href?: string
}

const MarkdownLink: React.FC<React.PropsWithChildren<MarkdownLinkProps>> = ({
  title,
  href = '',
  children,
}) => {
  const youtubeDetails = getYoutubeUrlDetails(href)

  if (youtubeDetails) {
    return <YoutubeLink title={title} {...youtubeDetails} />
  }

  if (/^(https:\/\/[^/]+)?\/shop\/(brand|collection|product)/.test(href)) {
    return children
  }
  if (
    /^(https:\/\/[^/]+)?\/shop\/info\/(click-and-collect|delivery)/.test(href)
  ) {
    return children
  }
  return (
    <Link
      href={href || ''}
      title={title}
      isExternal
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  )
}

export const Markdown: React.FunctionComponent<
  React.PropsWithChildren<MarkdownProps>
> = ({
  content,
  components,
  wrapperClass,
  sx,
  dataTestId,
}: MarkdownProps): JSX.Element => (
  <Box
    className={wrapperClass}
    data-test-id={dataTestId}
    sx={{ ...markdownStyle, ...sx }}
  >
    <ReactMarkdown
      skipHtml
      children={content || ''}
      plugins={[RemarkBreaks, RemarkGFM]}
      components={{
        a: ({ title, href, children }) => (
          <MarkdownLink title={title} href={href}>
            {children}
          </MarkdownLink>
        ),
        p: ({ children }) => (
          <Text isBodyText sx={{ color: 'undefined' }}>
            {children}
          </Text>
        ),
        h1: ({ children }) => <H1>{children}</H1>,
        h2: ({ children }) => <H2>{children}</H2>,
        h3: ({ children }) => <H3>{children}</H3>,
        h4: ({ children }) => <H4>{children}</H4>,
        h5: ({ children }) => <H5>{children}</H5>,
        h6: ({ children }) => <H6>{children}</H6>,
        ul: ({ children, ...props }) => (
          <UnorderedList {...props}>{children}</UnorderedList>
        ),
        ol: ({ children, ...props }) => (
          <OrderedList {...props}>{children}</OrderedList>
        ),
        li: ({ children, ...props }) => (
          <ListItem {...props}>{children}</ListItem>
        ),
        th: ({ children }) => (
          <th>
            <H4 sx={{ fontSize: 'font-lg', lineHeight: 'base' }}>{children}</H4>
          </th>
        ),
        img: ({ src, alt }) => (
          <CloudinaryImage
            imageSetOrImage={{
              url: String(src),
              type: 'SQUARE',
              alt,
            }}
            maxWidth={DEFAULT_MARKDOWN_IMAGE_WIDTH}
          />
        ),
        ...components,
      }}
    />
  </Box>
)
