import React from 'react'

interface YoutubeLinkProps {
  youtubeId: string
  title?: string
}

const YoutubeLink: React.FC<YoutubeLinkProps> = ({ title, youtubeId }) => (
  <span className="relative block h-0 overflow-hidden pb-[56.25%] pt-8">
    <iframe
      className="absolute left-0 top-0 h-full w-full border-0"
      allowFullScreen
      title={title || `YouTube video ${youtubeId}`}
      src={`https://www.youtube.com/embed/${youtubeId}`}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </span>
)

export default YoutubeLink
