import React from 'react'
import { InferGetStaticPropsType, NextPage } from 'next'
import { REVALIDATION_PERIODS } from '@scentregroup/shared/constants'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import { useGetHrefContext } from '@scentregroup/shared/context'
import { getDefaultNationalMetaData } from '@scentregroup/shared/helpers/get-default-national-metadata'
import Layout from '@scentregroup/shared/hub-components/layout'
import { completelyCanonicalUrl } from '@scentregroup/shared/helpers/canonical-link'
import { fetchNationalStaticData } from '@scentregroup/shared/helpers/fetch-header-static-data'
import { GetStaticPropsContext, GetStaticProps } from '../types/next'
import { makeApolloClient } from '../lib/apolloClient'
import { propsOrNotFound } from '../lib/props-or-not-found'
import ErrorFeature from '../../../src/shared/components/error-feature'

export const getStaticProps: GetStaticProps = async ({
  locale,
}: GetStaticPropsContext) => {
  const client = makeApolloClient()
  const country = localeToCountry(locale)

  const headerStaticData = await fetchNationalStaticData(client, {
    country: localeToCountry(locale),
  })

  return {
    ...propsOrNotFound({
      props: {
        headerStaticData,
        country,
      },
    }),
    revalidate: REVALIDATION_PERIODS.notFound,
  }
}

const NotFoundPage: NextPage<
  InferGetStaticPropsType<typeof getStaticProps>
> = props => {
  const { country, headerStaticData } = props
  const canonical = completelyCanonicalUrl(useGetHrefContext()())
  const defaultMetaData = getDefaultNationalMetaData({
    country: props.country,
    type: 'default',
  })

  const metaData = {
    title: defaultMetaData.title,
    description: defaultMetaData.description,
    canonical,
  }

  return (
    <Layout
      country={country}
      centre={undefined}
      topStrip={null}
      header={{
        variant: 'national',
        data: headerStaticData,
      }}
      metaData={metaData}
      showCVPStrip={false}
      showRobotsMetaTag={false}
    >
      <ErrorFeature status={404} />
    </Layout>
  )
}

export default NotFoundPage
