import React, { useEffect } from 'react'
import { CoreContainer } from '@hub/core-container'
import { Box } from '@hub/box'
import { LinkBox, LinkOverlay } from '@hub/link'
import { H1 } from '@hub/heading'
import { useAnalyticsSimplified } from '@scentregroup/analytics'
import { CloudinaryImage } from '../../hub-components/cloudinary-image'
import { Markdown } from '../../hub-components/markdown'
import { STATUS_NOT_FOUND } from '../../constants'
import analyticsUtils from '../../helpers/analytics-utils'
import errorMessages from './static-content'

type ErrorFeatureProps = {
  error?: unknown
  status: number
}

const ErrorFeature: React.FC<ErrorFeatureProps> = ({ error, status }) => {
  const INDENT = 2
  const content =
    errorMessages[status as keyof typeof errorMessages] ||
    errorMessages['default']

  const devMessage = `
  \`\`\`
  ${JSON.stringify(error, null, INDENT)}
  \`\`\`
  `

  const analytics = useAnalyticsSimplified()

  useEffect(() => {
    // Tracks 404 events for analytics
    if (status && status === STATUS_NOT_FOUND) {
      analytics.trackEvent({
        type: 'legacy',
        data: { event: analyticsUtils.events.NOT_FOUND_404 },
      })
    }
  }, [status, analytics])

  return (
    <Box>
      <LinkBox>
        <LinkOverlay href={content.link}>
          <CloudinaryImage
            imageSetOrImage={{
              url: 'https://images.scentregroup.io/image/upload/v1725247219/Misc/hero.jpg',
              type: 'WIDE',
              alt: content.title,
            }}
            resizeMode="pad"
            sizes="size-full"
          />
        </LinkOverlay>
      </LinkBox>

      <CoreContainer>
        <Box
          sx={{
            position: 'relative',
            transform: 'translateY(-50%)',
            background: 'white',
            zIndex: 2,
            padding: 'spacing-xl',
            maxWidth: 'fit-content',
          }}
        >
          <LinkBox sx={{ marginBottom: 'spacing-sm' }}>
            <LinkOverlay href={content.link}>
              <H1>{content.title}</H1>
            </LinkOverlay>
          </LinkBox>
          <Markdown content={content.body} />
          {typeof (global as any).__DEV__ !== 'undefined' &&
            (global as any).__DEV__ &&
            error && <Markdown content={devMessage} />}
        </Box>
      </CoreContainer>
    </Box>
  )
}

export default ErrorFeature
